<template>
    <div class="bg-white">
        <!-- Hero Section-->
        <section
            class="h-auto flex flex-col md:flex-row items-center justify-between bg-[url(/images/home/hero-left.png)] bg-no-repeat">
            <!-- Left Image Section -->
            <div class="md:w-2/3 h-full flex rounded-l-lg bg-left-top">
                <div class="m-10">
                    <div class="flex flex-col justify-between items-center">
                        <p
                            class="p-10 mt-10 text-center font-extrabold tracking-tight leading-none text-gray-900 text-5xl">
                            The Ultimate AI-Powered Exam Prep Platform
                        </p>
                        <p class="px-10 text-lg text-center font-normal text-gray-500 lg:text-xl">
                            The intelligent study companion powered by
                            AI. Say goodbye to long hours of manual content review. Streamline your study process by
                            automatically summarizing your study materials, creating categorized topics, and generating
                            key
                            questions—all tailored to your learning preferences.
                        </p>
                        <a href="/signup"
                            class="mt-5 p-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">Get
                            Started</a>
                    </div>

                </div>
            </div>

            <!-- Right Image Section (hidden on small devices) -->
            <div class="md:w-1/3 flex justify-end items-center hidden md:flex rounded-lg">
                <img src="/images/home/hero-right.png" alt="Right Section" class="rounded-r-lg">
            </div>
        </section>
        <h1 class="w-auto text-center text-3xl font-bold m-5">Create Prep Material Development for Colleges,
            Institutions & Individuals</h1>
        <section>
            <div class="p-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                <!-- AI Feature Cards -->
                <!-- Functionality 1 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/upload.png" alt="Upload Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Seamless Content Upload</h3>
                    <p>PREPTO allows you to effortlessly upload your study materials in various formats,
                        ensuring a seamless onboarding experience for users.</p>
                </div>
                <!-- Functionality 2 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/summarize.png" alt="AI Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">AI-Powered Summarization</h3>
                    <p>Experience the power of AI as it analyzes your study content and generates concise
                        and
                        targeted summaries, saving you valuable study time.</p>
                </div>
                <!-- Functionality 3 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/categories.png" alt="Categories Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Intelligent Categorization</h3>
                    <p>PREPTO dynamically categorizes study topics, allowing for personalized study sessions
                        and easy navigation based on difficulty and relevance.</p>
                </div>
                <!-- Functionality 4 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/mcq.png" alt="MCQ Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Smart Multiple Choice Questions</h3>
                    <p>Generate intelligent multiple-choice questions tailored to your study content,
                        enhancing
                        your understanding of key concepts.</p>
                </div>
                <!-- Functionality 5 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/key-point.png" alt="Key Point Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Effortless Key Point Extraction</h3>
                    <p>PREPTO identifies and extracts key points from your study materials, providing you
                        with
                        condensed insights for efficient learning.</p>
                </div>
                <!-- Functionality 6 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/study-plan.png" alt="Study Plan Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Personalized Study Plans</h3>
                    <p>PREPTO creates personalized study plans based on your learning preferences, ensuring
                        an
                        adaptive and effective study experience.</p>
                </div>
                <!-- Functionality 7 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/progress.png" alt="Progress Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Real-time Progress Tracking</h3>
                    <p>Track your progress in real-time, monitor strengths and weaknesses, and receive
                        actionable insights to optimize your study sessions.</p>
                </div>
                <!-- Functionality 8 -->
                <div class="p-8 bg-gray-50 rounded-xl hover:shadow-lg transition-shadow">
                    <div class="w-1/2">
                        <img src="/images/home/dashboard.png" alt="Dashboard Icon" class="w-32 h-32" />
                    </div>
                    <h3 class="text-xl font-bold my-2">Interactive Study Dashboard</h3>
                    <p>Access an interactive dashboard that provides a comprehensive overview of your study materials,
                        progress, and upcoming study tasks.</p>
                </div>

            </div>
        </section>
        <h1 class="w-auto text-center text-3xl font-bold m-5">Explore Our Library of Expert-Crafted Exam Preps</h1>
        <!-- Library Section -->
        <section>
            <div class="p-10 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-5">
                <!-- Driving Handbook -->
                <div
                    class="flex flex-col justify-center items-center p-8 bg-white rounded-xl hover:shadow-lg transition-shadow border border-gray-100">
                    <img class="text-center" width="250" height="250" src="/images/home/canadian-driving-handbook.png"
                        alt="Upload Icon" />
                    <h3 class="text-xl font-bold my-2 text-center">Canadian Driving Handbook</h3>
                    <p class="text-center">Prepare with our 1000 test questions, multiple choice tests, AI chatbot,
                        progress Dashboard.</p>
                    <a href="/prep?categoryId=3"
                        class="m-3 p-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">Details</a>
                </div>
                <!-- Canadian Citizenship -->
                <div
                    class="flex flex-col justify-center items-center p-8 bg-white rounded-xl hover:shadow-lg transition-shadow border border-gray-100">
                    <img class="text-center" width="250" height="250" src="/images/home/canadian-citizenship.png"
                        alt="Upload Icon" />
                    <h3 class="text-xl font-bold my-2 text-center">Canadian Citizenship Test Prep.</h3>
                    <p class="text-center">Prepare with our 1000 test questions, multiple choice tests, AI chatbot,
                        progress Dashboard.</p>
                        <a href="/prep?categoryId=2"
                        class="m-3 p-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">Details</a>
                </div>
                <!-- LLQP -->
                <div
                    class="flex flex-col justify-center items-center p-8 bg-white rounded-xl hover:shadow-lg transition-shadow border border-gray-100">
                    <img class="text-center" width="250" height="250" src="/images/home/LLQP.png" alt="Upload Icon" />
                    <h3 class="text-xl font-bold my-2 text-center">Life License Qualification Program (LLQP).</h3>
                    <p class="text-center">Prepare with our 1000 test questions, multiple choice tests, AI chatbot,
                        progress Dashboard.</p>
                        <a href="/prep?categoryId=4"
                        class="m-3 p-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">Details</a>
                </div>
            </div>
        </section>
        <!-- Contact Us Section -->
        <section id="contact-us" class="bg-gray-50 py-16">
            <div class="container mx-auto">
                <h2 class="text-3xl font-bold mb-8 text-center">Contact Us</h2>

                <div class="flex flex-col md:flex-row justify-center items-center">
                    <!-- Contact Form (Replace this with your actual contact form code) -->
                    <form @submit.prevent="submitForm" class="md:w-1/2 max-w-lg">
                        <div class="mb-4">
                            <label for="name" class="block text-sm font-medium text-gray-600">Name</label>
                            <input required v-model="name" type="text" id="name" name="name"
                                class="mt-1 p-2 w-full border rounded-md" />
                        </div>
                        <div class="mb-4">
                            <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
                            <input required v-model="email" type="email" id="email" name="email"
                                class="mt-1 p-2 w-full border rounded-md" />
                        </div>
                        <div class="mb-4">
                            <label for="message" class="block text-sm font-medium text-gray-600">Message</label>
                            <textarea required v-model="message" id="message" name="message" rows="4"
                                class="mt-1 p-2 w-full border rounded-md"></textarea>
                        </div>
                        <button type="submit"
                            class=" p-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">Submit</button>
                    </form>

                    <!-- Contact Information -->
                    <div class="mt-8 md:mt-0 md:ml-8 text-center md:text-left">
                        <p class="text-lg mb-4">Have questions or need assistance?</p>
                        <p class="text-gray-600">Feel free to reach out to us:</p>
                        <p class="text-gray-600">Email: info@prepto.pro</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import { showToastMessage } from '~/common/utils';

useHead({
  title: "The Ultimate AI-Powered Exam Prep Platform | PREPTO",
  meta: [
    {
      name: "description",
      content: "PREPTO is the intelligent AI-powered exam prep platform that streamlines your study process. Effortlessly upload study materials, get AI-powered summaries, generate smart multiple-choice questions, and track progress in real-time."
    },
    { name: "keywords", content: "AI exam prep, AI-powered study tool, smart exam preparation, AI learning platform, multiple choice questions generator, interactive study dashboard" },
    { name: "robots", content: "index, follow" },

    // Open Graph (Facebook, LinkedIn)
    { property: "og:title", content: "The Ultimate AI-Powered Exam Prep Platform | PREPTO" },
    { property: "og:description", content: "PREPTO is the intelligent AI-powered exam prep platform that streamlines your study process with AI-powered summaries, smart MCQs, and real-time tracking." },
    { property: "og:image", content: "/images/home/prepto.png" }, 
    { property: "og:url", content: "https://www.prepto.pro" },
    { property: "og:type", content: "website" },

    // Twitter Card (For Twitter SEO)
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "The Ultimate AI-Powered Exam Prep Platform | PREPTO" },
    { name: "twitter:description", content: "AI-powered exam prep platform that enhances your study experience with intelligent summaries, personalized study plans, and smart MCQs." },
    { name: "twitter:image", content: "/images/home/prepto.png" }
  ]
});

const name = ref('');
const email = ref('');
const message = ref('');

const submitForm = async () => {
    try {
        const { data, error } = await useFetch('/api/log', {
            method: 'POST',
            body: {
                email: 'info@prepto.pro',
                message: message.value,
                name: "User has sent a new message",
                notify: true,
                severity: '1',
                status: "Contact Form",
                info: {
                    name: name.value,
                    email: email.value
                }
            }
        });

        if (error.value) {
            showToastMessage('Failed to send message', { type: "error" });
        } else {
            console.log(data);
            showToastMessage('Message sent successfully', { type: "success" });
            // Clear the form fields
            name.value = '';
            email.value = '';
            message.value = '';
        }
    } catch (err) {
        console.error('Form submission error:', err);
        alert('Failed to send message');
    }
};
</script>
